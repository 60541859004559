.enrollmentCount {
	box-shadow: 1px 1px 1px 1px #c8c8c8;
	margin-right: 20px;
	width: 22%;
	background-color: var(--clr-white);
	position: relative;
	display: inline-block;
}

.enrollmentImg {
	width: 80px;
	height: 80px;
}

.enrollmentTotalCount {
	position: absolute;
	top: 0;
	padding-left: 10px;
	height: 80px;
	font-size: 15px;
	font-weight: 500;
	left: 0;
	transform: translate(50%, 10px);
	width: 70%;
}

.enrollmentTotalCount p:first-child {
	font-size: 20px;
	margin: 0;
}

.enrollmentHeader {
	font-weight: 500;
	border-bottom: 2px solid #c8c8c8;
	padding-bottom: 5px;
	margin-bottom: 10px;
}

.engagementStats {
	position: relative;
	top: 0;
	margin-top: 2%;
	padding: 0;
	height: 300px;
	background-color: var(--clr-white);
	box-shadow: 1px 1px 1px 1px #c8c8c8;
}

.enrollmentDegreeTabs {
	box-shadow: 1px 1px 1px 1px #c8c8c8;
	height: 70px;
	padding: 0;
	width: 23%;
	margin-right: 2%;
	background-color: var(--clr-white);
	display: inline-block;
	margin-bottom: 2%;
	position: relative;
	cursor: pointer;
}

.enrollmentDegreeTabs .title {
	font-size: 13px;
	padding-left: 5px;
}

.enrollmentDegreeTabs .count, .enrollmentDegreeTabs .title {
	position: relative;
	height: 100%;
}

.enrollmentDegreeTabs .title p, .enrollmentDegreeTabs .count p {
	margin: 0;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.enrollmentDegreeTabs .count {
	font-size: 20px;
	padding: 1px
}

.enrollmentTimelineX {
	position: absolute;
	top: calc(100% - 114px);
	left: calc(50%);
}

.enrollmentTimelineY {
	position: absolute;
	transform: rotate(-90deg);
	left: -7%;
	top: calc(50% - 67px)
}

.enrollmentProjectsList {
	cursor: pointer;
	position: relative;
	font-size: 12px;
}

.enrollmentProjectsList span {
	position: absolute;
	top: calc(50% - 9px);
	left: 6px;
	border: 1px solid #c8c8c8;
	height: 18px;
	width: 18px;
	border-radius: 5px;
}

.enrollmentProjectsList .fa-check {
	color: #00ab6f;
	font-size: 12px;
	position: relative;
	top: 0px;
	left: 2px;
}

.enrollmentProjectsList li {
	margin-bottom: 4px;
	position: relative;
}

.instituteTableProgressIcon {
	top: calc(100% - 25px);
	text-align: center;
	width: 55px;
	left: 0;
	padding: 0!important;
	position: absolute!important;
	color: #e6e6e6;
	font-size: 25px;
}

.enrollmentYearDiv {
	padding: 0px;
	box-shadow: 1px 1px 1px 1px #c8c8c8;
	background-color: var(--clr-white);
	margin-top: 2%;
}

.enrollmentBranchDiv {
	padding: 0px;
	width: 50%;
	margin-right: 8.33%;
	background-color: var(--clr-white);
	height: 210px;
	box-shadow: 1px 1px 1px 1px #c8c8c8;
}

.enrollment.teamStatsTable {
	border-radius: 0px;
	width: 92%;
	margin-left: 4%;
	margin-top: 2%;
}

.enrollment.projectStatsTable {
	border-radius: 0px;
}

.enrollment.projectStatsTable td {
	padding: 10px;
}

.enrollment.projectStatsTable td {
	padding: 10px!important;
	text-align: center!important;
}

.enrollment.projectStatsTable tbody td {
	color: var(--clr-black)!important;
}

.enrollmentTableDiv {
	height: auto;
	padding-bottom: 3%;
}

.enrollmentProjectFilterBtn {
	background-color: var(--clr-button-orange);
	border: none;
	padding: 5px 15px;
	color: var(--clr-white);
	margin-right: 0;
	cursor: pointer;
}

.enrollmentProjectFilterBtn:focus, .enrollmentProjectFilterBtn:hover {
	background-color: #f65f06;
	color: var(--clr-white);
	box-shadow: none;
}

.projectDashboardContainer {
	width: calc(100% - 60px);
	float: right;
}