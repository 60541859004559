.resourceCreationContainer {
	position: relative;
	width: 70%;
	margin-left: 17%;
	margin-bottom: 20px;
	top: 0;
}

.resourceCatalogContainer {
	position: relative;
	width: 80%;
	margin-left: 13%;
	margin-bottom: 20px;
	top: -10px;
}

.resourcesHeader {
	position: fixed;
	z-index: 2;
	top: 53px;
	background-color: var(--clr-white);
	border-bottom: 1px solid #e6e6e6;
	width: inherit;
}

.selectResourceTitle {
	font-size: 18px;
}

.width86 {
	width: 86%;
}

.selectedResourceCount {
	/* float: right;
      border: 1px solid grey;
      margin-top: -3px;
      border-radius: 3px;
      background-color: #e5e5e575;
      font-size: 15px;
      height: 20px;
      width: 20px; */
	position: absolute;
	right: 7px;
	top: 7px;
	border: 1px solid grey;
	border-radius: 3px;
	background-color: #e5e5e5;
	font-size: 15px;
	height: 20px;
	width: 20px;
}

.selectResourceDiv {
	margin-bottom: 10px;
}

.selectResourceHeader {
	border-bottom: 1px solid #e6e6e6;
	margin-bottom: 20px;
}

.resourcesHeader .resourceTitle {
	border-bottom: 1px solid #f2f2f2;
	border-top: 1px solid #f2f2f2;
	background-color: #f2f2f2;
	height: 50px;
	padding: 10px;
}

.resourcesHeader .catalogHeader {
	border-bottom: 1px solid #c8c8c8;
	padding: 10px;
	height: 50px;
}

.resourceHeaderText {
	font-size: 19px;
	color: #7e7e7e;
}

.resourceGrpTextColor {
	color: #636060;
	font-size: 15px;
	padding: 0 20px 0 0;
	text-align: justify;
}

.resourceContentTopicHeading {
	font-size: 22px;
	font-weight: 500;
}

.resourceContent {
	position: relative;
	top: 60px;
	margin-top: 10px;
	margin-bottom: 20px;
}

.resourceFlowCardTitle {
	padding: 0;
	padding-bottom: 7px;
	font-weight: bold;
	margin-top: 10px;
}

.resourceFlowCardDesc img {
	width: 100% !important;
}

.resourceFlowSequenceDiv {
	border-radius: 50%;
	width: 40px;
	height: 40px;
	position: absolute;
	padding: 0;
	padding-top: 7px;
	font-weight: bold;
	font-size: 17px;
}

.resourceFlowCardDiv {
	margin-left: 15px;
	border-radius: 7px;
	cursor: pointer;
}

.resourceInputFields {
	outline: none;
}

.resourceGroupContent {
	top: 225px;
	position: relative;
}

.resourceFields {
	margin-bottom: 10px;
	padding-right: 0;
}

.resourceFields label {
	width: 100%;
	font-weight: 400;
}

.resourceFields input[type="text"], .resourceFields input[type="number"] {
	width: 100%;
	border-radius: 5px;
	box-shadow: none;
	height: 36px;
	border: 1px solid #e6e6e6;
	padding: 5px;
}

.resourceSubSectionSummary {
	font-weight: 400;
	margin-bottom: 20px;
	font-size: 13px;
	color: gray;
}

.buttonsDiv {
	margin-bottom: 25px;
}

.buttonsDiv button {
	margin-right: 10px;
}

.questionsDropDown {
	border-bottom: 1px solid #e6e6e6;
	margin-bottom: 10px;
	margin-left: 0;
}

.resourceCard {
	height: 200px;
	width: 30%;
	margin-left: 1%;
	position: relative;
	margin-right: 2%;
	margin-bottom: 10px;
	padding: 0px 5px;
	transition: transform 0.3s;
}

.resourceCard:hover {
	transform: scale(1.02)
}

.domainsList {
	min-height: 300px;
}

.truncateHtmlContent {
	height: 115px;
	overflow: hidden;
	font-size: 12px;
	font-family: europa, sans-serif;
}

.resourceGroupTitleDiv {
	position: absolute;
	top: 25px;
	left: 50%;
	transform: translate(-50%);
}

.resourceGroupSpecDiv {
	position: absolute;
	top: 90px;
	left: 50%;
	transform: translate(-50%);
}

.resourceGroupTimeToReadDiv {
	position: absolute;
	left: 50%;
	bottom: 5px;
	transform: translate(-50%);
	background-color: var(--clr-white);
	padding: 8px;
	font-size: 14px;
	color: #a1a1a1;
	border-radius: 4px;
}

.resourceGroupSpecItem {
	display: inline-block;
	margin: 0 5px;
	border: 1px solid grey;
	padding: 2px 6px;
	font-size: 13px;
	border-radius: 4px;
}

.resourceGroupEditBtn {
	font-size: 14px;
	position: absolute;
	right: 1%;
}

.subjectDivResourceGroup {
	border: 1px solid #b5acac;
	font-size: 11px;
	padding: 5px;
	background-color: #d8d0d0;
	border-radius: 4px;
	color: #ff6211;
	position: absolute;
	width: fit-content;
	top: 0;
	left: 50%;
	transform: translate(-50%, 0%);
}

.truncateHtmlContent::after {
	background: linear-gradient(0deg, var(--clr-white), rgba(255, 255, 255, 0) 100%);
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	height: 60px;
	width: 100%;
}

.resourceSearch {
	border: 1px solid #d5d4d4;
	border-radius: 7px;
	background-color: var(--clr-white);
	padding: 0px 10px;
}

.resourceSearch input {
	border: none;
	outline: 0;
	color: #8b8b8b;
	font-weight: 100;
	font-size: 13px;
	width: calc(100% - 13px);
	padding: 7px;
}

.resourceAuthor {
	width: 12%;
	top: 104px;
	height: min-content;
	position: fixed;
	right: 0;
	padding: 0px 15px;
}

.resourceAuthorImg {
	width: 60px;
	min-height: 60px;
	object-fit: cover;
	border: 2px solid #a883a4;
	border-radius: 50%;
}

.resourceFields .Select-menu-outer {
	z-index: 2;
}

.questChargeDiv {
	position: relative;
	top: 5px;
	color: var(--clr-white);
	font-weight: 300;
	padding: 2px;
}

.resourceGroupHeaderDiv {
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	height: 250px;
}

.resourceFlowCardInfo {
	border-radius: 50%;
	width: 25px;
	height: 25px;
	padding: 0px;
	padding-top: 7px;
	font-weight: bold;
	font-size: 15px;
	display: inline-block;
}

.resourceFlowCardInfo i {
	position: absolute;
	left: 21px;
	top: 6px;
	color: #fff;
	font-size: 13px;
}

.resourceFlowCardInfoStatus {
	position: absolute;
	top: 3px;
	left: 45px;
	font-size: 14px;
}

.flowCardMaxHeight {
	max-height: 160px;
	overflow: hidden;
	border-bottom-left-radius: 7px;
	border-bottom-right-radius: 7px;
}

.resourceQueryHtml img, .flowCardMaxHeight img {
	max-width: 100%;
}

.flowCardMaxHeight::after {
	content: "";
	background: linear-gradient(0deg, var(--clr-white), hsla(0, 0%, 100%, 0) 100%);
	position: absolute;
	left: 0;
	bottom: 0;
	height: 60px;
	width: 100%;
}

.knowMoreDetailSelect {
	position: absolute;
	bottom: 4px;
	left: 0;
	padding: 3px 8px 0 0;
	font-size: 12px;
	color: var(--clr-dark-blue);
	cursor: pointer;
}

.filterComponentSelect {
	width: 30%;
	margin-right: 10px;
}

.resourceCard h5 {
	margin: 10px 0px;
}

.resourceCreationContainer .linkBtn {
	padding: 5px 6px;
}

.RatingContent-centerAlign {
	position: relative;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
}

.ratingInterventionMainClass-quest {
	position: relative;
	left: 17%;
	width: 82%;
	border-bottom: 1px solid #D5D5D5;
}

.RatingSummary-rateIcon {
	width: 50%;
	display: inline-block;
	left: 30%;
}

.resourceCard-startBtn {
	background: transparent;
	border: 1px solid var(--experience-color);
	padding: 2px 10px;
	font-size: 15px;
	float: right;
}

@media (max-width: 767px) {
	.resourceCreationContainer {
		width: 96%;
		margin: 0 2%;
	}
	.resourceCatalogContainer {
		width: 96%;
		margin-left: 2%;
	}
	.resourceCard {
		width: 49%;
		margin-left: 0;
		margin-right: 1%;
	}
	.resourceTitle {
		text-align: left;
	}
	.resourceGroupTitleHeader {
		font-size: 20px;
	}
	.resourceGroupSpecItem {
		font-size: 11px;
		text-align: center;
	}
	.resourceGroupTimeToReadDiv {
		padding: 3px;
	}
	.filterComponentSelect {
		width: 100%;
	}
	.resourceGrpTextColor {
		padding: 0;
	}
}