.challengeContainer {
  background-color: var(--clr-white);
  height: 100%;
}

.challengeContainer .headerLogo {
  padding: 0px;
}

.challengeContainer .PanelHeading {
  border: 1px solid var(--clr-button-orange);
  background-color: var(--clr-white);
  border-radius: 0px;
}

.challengeContainer .PanelHeading h4 {
  color: #B71C1C;
}

.challengeContainer .PanelBody {
  border: 1px solid var(--clr-button-orange);
  font-size: 14px;
  background-color: #f0f0f0;
}

.challengeContainer .buttons .register {
  background-color: #B71C1C;
  color: var(--clr-white);
  margin-top: 10px;
  border: 1px solid transparent;
  font-size: 17px;
  line-height: 30px;
  letter-spacing: 1px;
}

.challengeContainer p {
  margin: 0px;
}

.challengeContainer .headerText .type1 {
  font-size: 54px;
  color: #B71C1C;
  line-height: 52px;
}

.challengeContainer .headerText {
  padding-top: 24px;
}

.challengeContainer .headerText .type2 {
  color: #B71C1C;
  font-weight: 400;
  margin-top: 20px;
}

button {
  outline: 0;
}

.challengeContainer .buttons .type1 {
  color: var(--clr-button-orange);
  border: 1px solid var(--clr-button-orange);
  font-size: 17px;
  background: transparent;
  line-height: 30px;
  letter-spacing: 1px;
}

.challengeContainer .textContainer {
  margin-top: 20px;
}

.challengeContainer .textContainer .type1 {
  font-size: 31px;
  color: var(--clr-button-orange);
  font-weight: 300;
}

.challengeContainer .textContainer .type1 span {
  color: #B71C1C;
}

.challengeContainer .textContainer .type2 {
  color: #B71C1C;
  font-size: 14px;
  text-align: justify;
}

.challengeContainer .textContainer .type3 {
  color: var(--clr-button-orange);
  font-size: 20px;
  text-align: justify;
  margin: 5px 0px;
}

.challengeContainer .textContainer .phases {
  padding-left: 0px;
  margin-top: 20px;
}

.challengeContainer a {
  text-decoration: none;
}

.challengeContainer .updates {
  border: 2px solid var(--clr-button-orange);
  margin-top: 20px;
  text-align: center;
}

.challengeContainer .updates h5 {
  font-size: 19px;
  color: var(--clr-button-orange);
  font-weight: 300;
}

.challengeContainer .rules {
  margin-top: 20px;
}

.challengeContainer .rules .type1 {
  font-size: 25px;
  color: var(--clr-button-orange);
  font-weight: 300;
  margin-bottom: 10px;
}

.challengeContainer .rules .type2 {
  color: #B71C1C;
  font-size: 12px;
}

.AccordianClass .card {
  background-color: var(--clr-button-orange);
  color: var(--clr-white);
  border-radius: 0px;
  margin-bottom: 1px;
}

.AccordianClass .card .card-header {
  padding: 10px 15px;
  background-color: transparent;
  cursor: pointer;
  border-bottom: none;
}

.AccordianClass .card .card-header h5 {
  text-decoration: none;
  color: var(--clr-white);
  font-size: 16px;
}

/* .AccordianClass .card-header a:hover {
  text-decoration: none;
  color: var(--clr-white);
} */

.AccordianClass .card .card-body {
  background-color: var(--clr-white);
  color: var(--clr-black);
  font-size: 14px;
}

.challengeContainer .participants a {
  text-decoration: none;
  color: var(--clr-black);
}

.challengeContainer .participants a:hover {
  text-decoration: none;
  color: var(--clr-black);
}

.challengeContainer .socialIcons {
  color: var(--clr-button-orange);
  margin-bottom: 5px;
}

.AccordianClass .type3 {
  font-size: 11px;
  font-weight: 300;
}

.accordionProjectTitle {
  font-size: 16px;
}

.AccordianClass .StudentDetailsContainer {
  position: fixed;
  z-index: 1;
  background-color: rgb(233 232 232);
  height: 5%;
  display: flex;
  box-shadow: 0px 1px 5px rgba(169, 167, 167, 0.7);
  max-width: 65%;
  vertical-align: middle;
}

