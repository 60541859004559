:root {
--clr-select-blue:   #2684FF;
--clr-blue:          #4FC1E9;
--clr-dark-blue:     #3BAFDA;
--clr-navy:          #477EA8;
--clr-dark-navy:     #345C7C;
--clr-teal: 				 #096B7B;

--clr-mint:          #48CFAD;
--clr-dark-mint:     #37BC9B;
--clr-completed-green:#2CB72C;
--clr-forestgreen:  #3f7b09;
--clr-toast-success:   #28a745;

--clr-pink:          #EC87C0;
--clr-dark-pink:     #D770AD;

--clr-red:           #ED5565;
--clr-dark-red:      #DA4453;

--clr-button-orange: #F77004;
--clr-maroon:        #AC272E;
--clr-message:			 #AB1D32;
--clr-toast-delete:  #dc3545;

--clr-yellow:        #FFCE54;
--clr-dark-yellow:   #F6BB42; 

--clr-lighter-grey:  #C5C5C5;
--clr-light-grey:    #C6C6C6;
--clr-grey:          #BBBBBB;
--clr-dark-grey:     #9B9B9B;
--clr-darker-grey:   #8C8C8C;
--clr-darkest-grey:  #555459;
--clr-black:				 #000000;

--clr-white:				 #FFFFFF;
--clr-snow:					 #F9F9F9;
--clr-base:          #F5F5F5;
--clr-dark-base:     #D8D8D8;

--clr-green:         #8FBD62;
--education-color:   #FF5E3D;
--experience-color:  #FD8D30;
--exposure-color:    #F2B10F;

--spot-yellow:			 #F4A200;
--spot-maroon:			 #68191B;
--spot-red:			     #C12A21;
--text-black:        #272525;
--bg-grey-dark:      #696867;
--bg-grey-light:     #E0DEDE;
--bg-light:          #FFF6F3;
--coral-candy:			 #F7C8C8;
--primary-white-2:      #f2f2f2;
--primary-white-3:      #b1afaf;
--highlighted-orange:   #e29a34;
--mids-grey-1:          #848383;
--mids-grey-2:          #424242;
--mids-grey-3:            #858585;
--link-blue-color:        #1a0dab;
--casual-text-color:    #212529;
--grey-differentiator: #DFDFE0;
--description-text-grey: #76767b;
--std-function-skin:     #efb797;
--std-function-green:     #9ccba9;
--std-function-lemon:     #e5e27c;
--std-function-pink:     #e09ca4;
--std-function-yellow:     #f4ee82;
}