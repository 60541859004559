.form-group{position:relative;}
.form-control[disabled]{border-bottom:1px dashed rgba(0, 0, 0, 0.12);background-color:transparent;}
.form-control:focus,
.form-control.has-focus,
.Select:focus,
.Select.has-focus{outline:none;}
.form-control:focus ~ label::after,
.form-control.has-focus ~ label::after,
.Select:focus ~ label::after,
.Select.has-focus ~ label::after{visibility:visible;width:100%;left:0;}
.form-control ~ label,
.Select ~ label,
.radio-control ~ label{width:100%;position:absolute;pointer-events:none;left:2px;top:8px;bottom:0;font-size:12px;font-weight:normal;font-style:normal;font-stretch:normal;color:#686868;}
.input-group .form-control ~ label{z-index:2;left:40px;}
.input-group .form-control.float ~ label,
.input-group .form-control:focus ~ label{left:2px;top:-14px;}
.form-control ~ label,
.Select ~ label{transition:0.2s ease all;}
.form-control.float ~ label,
.form-control:focus ~ label,
.Select.float ~ label,
.Select:focus ~ label,
.radio-control ~ label{visibility:visible;top:-8px;font-size:9px;}
.radio-control ~ label{left:-1px;}
.Select.float ~ label,
.Select:focus ~ label{top:-14px;}
.form-control ~ label > span.asterisk,
.Select ~ label > span.asterisk,
.radio-control ~ label > span.asterisk,
.form-label > span.asterisk,
.Select-placeholder > label > span.asterisk,
label span.asterisk{color:#ed4956;}

.Select-placeholder > label{color:#686868;font-size:12px;font-weight:normal;font-style:normal;font-stretch:normal;}