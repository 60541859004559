.LoginPopup {
	background-color: transparent;
	width: 450px;
	position: relative;
	outline: 0;
	padding-bottom: 7px;
	height: 100%;
	perspective: 1000px;
}

.LoginCard {
	width: 100%;
	height: 100%;
	transition: transform 1s;
	transform-style: preserve-3d;
	position: relative;
}

.LoginCard.forgotPasswordCard {
	height: 300px;
}

.LoginCard.phoneAuthCard {
	height: 420px;
}

.LoginContent.forgotLoginPassword .loginHeading {
	text-align: center;
	margin: 0 0 12px 0;
}

.LoginContent.forgotLoginPassword .mainDiv {
	padding: 4px 20px;
}

.LoginContent.forgotLoginPassword .mainDiv .infoText {
	text-align: left;
	font-size: 15px;
	margin-bottom: 5px;
}

.LoginContent.forgotLoginPassword .mainDiv .emailInput {
	width: 100%;
	padding: 5px;
	outline: none;
	height: 40px;
	border: 1px solid #F77004;
	border-radius: 2px;
	position: absolute;
}

.LoginContent.forgotLoginPassword .mainDiv .recoveryBtn {
	margin-top: 20px;
	width: 100%;
	padding: 10px;
	border-radius: 3px;
	border: 1px solid #F77004;
	background-color: #F77004;
	color: #fff;
}

.LoginContent.forgotLoginPassword .mainDiv .recoveryBtn.disabled {
	margin-top: 20px;
	width: 100%;
	padding: 10px;
	border-radius: 3px;
	border: 1px solid var(--primary-white-2);
	background-color: var(--primary-white-2);
	color: var(--mids-grey-1);
}

.LoginContent.forgotLoginPassword .mainDiv .returnLoginDiv {
	text-align: center;
	margin-top: 20px;
	border-top: 2px solid #c5c5c5;
	padding-top: 18px;
}

.LoginContent.forgotLoginPassword .mainDiv .returnLoginDiv span {
	color: #F77004;
	cursor: pointer;
}

.LoginContent.forgotLoginPassword .mainDiv .forgotInputDiv {
	position: relative;
	height: 40px;
}

.LoginContent {
	position: absolute;
	top: 0;
	left: 0;
	background-color: var(--clr-white);
	border-radius: 0.3rem;
	height: 100%;
	width: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	padding: 15px;
	z-index: 2050;
}

.LoginContent.signIn {
	z-index: 2060;
	-webkit-transform: rotateY(0deg);
	transform: rotateY(0deg);
	transition: transform 1s;
	transform-style: preserve-3d;
}

.LoginCard.is-flipped .LoginContent.signIn {
	z-index: 2050;
}

.LoginCard.is-flipped {
	-webkit-transform: rotateY(180deg);
	transform: rotateY(180deg);
}

.LoginContent.register {
	-webkit-transform: rotateY(180deg);
	transform: rotateY(180deg);
	transition: transform 1s;
	transform-style: preserve-3d;
}

.LoginCard.is-flipped .LoginContent.register {
	z-index: 2060;
}

.LoginContent .loginInput {
	width: 90%;
	border: 1px solid var(--bg-grey-light);
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	height: 30px;
	padding: 0px 5px;
	position: absolute;
	top: 0;
	outline: none;
}

.LoginContent .inputDiv {
	margin-top: 20px;
	color: var(--text-black);
}

.LoginContent .inputDiv .headingText {
	text-align: center;
}

.LoginContent .inputDiv .inputLogo {
	border: 1px solid var(--bg-grey-light);
	padding: 5px 7px;
	color: #424242;
	font-size: 18px;
	border-right: none;
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	position: relative;
	height: 30px;
	width: 10%;
}

.loginBtn {
	color: #fff;
	background-color: #F77004;
	border: none;
	padding: 9px;
	font-size: 13px;
	font-weight: bold;
	border-radius: 2px;
	outline: none;
	width: 130px;
}

.LoginContent .linkBtn {
	font-size: 15px;
	text-decoration: none;
	padding: 0;
	font-weight: 400;
	color: #F77004;
}

.LoginContent .forgotPassword {
	color: var(--bg-grey-dark);
	background: transparent;
	border: none;
	font-size: 14px;
	padding: 0;
}

.formOrSocial {
	color: var(--bg-grey-dark);
	font-weight: 400;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
}

.formOrSocial::before, .formOrSocial::after {
	content: '';
	border-bottom: 2px solid var(--bg-grey-light);
	flex: 1 1;
	width: 44%;
	margin: 5px;
	display: inline-block;
	position: relative;
	top: 0px;
}

.socialLogin button {
	margin: 10px 0px;
	color: #fff;
	border: none;
	padding: 10px 5px;
	font-weight: bold;
	font-size: 13px;
	width: 205px;
	position: relative;
}

.socialLogin button i {
	margin-right: 10px;
	position: absolute;
	left: 12px;
	top: calc(50% - 6px);
}

.socialLogin button span {
	margin-left: 15px;
}

.socialLogin .fbLogin {
	background-color: #1877F2;
}

.socialLogin .googleLogin {
	background-color: #DD4B39;
}

.socialLogin .linkedinLogin {
	background-color: #0083A8;
}

.toggleShowIcon {
	float: right;
	padding: 5px 0;
	color: #424242;
	font-size: 18px;
	border-right: none;
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	position: relative;
	height: 30px;
	width: 8%;
}

.LoginContent.forgotLoginPassword .mainDiv .forgotInputDiv .toggleShowIcon {
	float: right;
	padding: 5px 0;
	color: #424242;
	font-size: 18px;
	border-right: none;
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	position: relative;
	height: 30px;
	width: 8%;
	top: 5px;
}

.fbIcon {
	width: 20px;
	height: 23px;
	position: absolute;
	left: 9px;
	top: calc(50% - 13px);
}

.loginTooltip {
	z-index: 2100;
}

.loginTooltip>.tooltip-inner {
	background-color: #e03202 !important;
	color: var(--clr-white) !important;
	border: 1px solid #e03202 !important;
}

.loginTooltip>.arrow::before {
	border-right: 5px solid #e03202 !important;
}

.LoginCard .loaderDiv, .LoginCard.is-flipped .loaderDiv, .PasswordReset-MainDiv .loaderDiv {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	height: 100%;
	background-color: #f2f2f2;
	z-index: 2100;
	opacity: 0.85;
	display: flex;
	justify-content: center;
	align-items: center;
}

.LoginCard .loaderDiv i, .LoginCard.is-flipped .loaderDiv i, .PasswordReset-MainDiv .loaderDiv i {
	font-size: 80px;
	color: var(--education-color);
}

.SecurityUpdate {
	height: 370px;
	margin-top: 15%;
}

.phoneNumberAuthField {
	outline: none;
	border-radius: 3px;
	padding-left: 5px;
    border: 1px solid #cccccc;
    margin-left: 10px;
	width: 80%;
	height: 38px;
}
.phoneNumberAuthField:focus {
	border: 2px solid var(--clr-select-blue);
}

.selectCountryCodeDiv{
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.countryCodeAvailable {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--clr-button-orange);
    border-radius: 3px;
	color: #fff;
	cursor: pointer;
	padding: 8px 12px;
	width: 20%;
}

.countryCodeAvailable.disabledStyle {
    background-color: var(--primary-white-2);
	color: var(--mids-grey-1);
	cursor: not-allowed;
}

.partitionedCode {
	padding-left: 15px;
	letter-spacing: 42px;
	border: 0;
	background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
	background-position: bottom;
	background-size: 50px 1px;
	background-repeat: repeat-x;
	background-position-x: 35px;
	width: 78%;
    outline: none;
    height: 40px;
}
.inputOTPVerification {
	border: none;
	border-bottom: 1px solid black;
	margin: 10px;
	padding: 5px;
	width: 30px;
	outline: none;
	text-align: center;
}
.VerificationCodeDiv {
	display: flex;
	justify-content: center;
}

#recaptcha-container div.grecaptcha-badge {
    bottom: -61px !important;
    right: 0px !important;
}

@media (min-width: 768px) and (max-width: 1023px) {
	.socialLogin button {
		font-size: 12px;
		padding: 5px;
	}
	.formOrSocial {
		margin-top: 10px;
		margin-bottom: 10px;
	}
	.LoginContent .inputDiv {
		margin-top: 15px;
	}
}

@media (max-width: 767px) {
	.formOrSocial::before, .formOrSocial::after {
		width: 40%;
	}
	.LoginContent .inputDiv {
		margin-top: 15px;
	}
	.formOrSocial {
		margin-top: 10px;
		margin-bottom: 10px;
	}
	.LoginPopup {
		height: 60%;
	}
}

@media (max-width: 767px) and (max-height: 900px) {
	.LoginPopup {
		height: 60%;
	}
	.SecurityUpdate {
		height: 92%;
		margin-top: 0;
	}
}

@media (max-width: 767px) and (max-height: 800px) {
	.LoginPopup {
		height: 67%;
	}
	.SecurityUpdate {
		height: 87%;
		margin-top: 0;
	}
}

@media (max-width: 767px) and (max-height: 700px) {
	.LoginPopup {
		height: 75%;
	}
	.SecurityUpdate {
		height: 98%;
		margin-top: 0;
	}
}

@media (max-width: 767px) and (max-height: 600px) {
	.LoginPopup {
		height: 100%;
	}
	.SecurityUpdate {
		height: 100%;
		margin-top: 0;
		font-size: 15px;
	}
}