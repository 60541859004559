.activity {
    background-color: white;
    margin: 30px;
    padding-left: 4%;
    height: 100%;
  }
  
  .custom-table {
    border: 1px solid #F77126;
    width: 100%;
    color: white;
  }
  
  .custom-table-tbody {
    height: 200px;
    display: inline-block;
    width: 100%;
    overflow: auto;
    color: black;
  }
  
  .activity-tab {
    float: left;
    background-color: #ffffff;
    width: 20%;
    min-height: max-content;
    height: 600px;
    border-right: 1px solid #F77126;
    overflow-y: scroll;
  }
  
  /* Style the buttons inside the tab */
  .activity-tab button {
    display: block;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    font-size: 17px;
    height: 59px;
    border-bottom: 2px solid #fa8954;
  }
  
  /* Change background color of buttons on hover */
  .activity-tab button:hover {
    background-color: #fa8954;
  }
  
  /* Create an active/current "tab button" class */
  .activity-tab button.active {
    background-color: rgb(255, 255, 255);
  }
  
  /* Style the tab content */
  .activity-tabcontent {
    float: left;
    padding: 0px 12px;
    width: 80%;
    min-height: max-content;
    height: 600px;
    overflow-y: scroll;
  }

  /* button styling */

.peerActivityBtn {
	background-color: var(--clr-button-orange);
	border: none;
	padding: 5px 15px;
	color: var(--clr-white);
	margin-right: 0;
	cursor: pointer;
  float: right;
}

.peerActivityBtn:focus, .peerActivityBtn:hover {
	background-color: #f65f06;
	color: var(--clr-white);
	box-shadow: none;
}

/* Peer learning scroll */

.peerLearningCard {
  min-height: max-content;
  max-height: 700px;
  overflow-x: scroll;
}

/* Create three unequal columns that floats next to each other */
.activityColumn {
  float: left;
}

.middle, .right, .right2 {
  width: 16.66%;
}

.left {
  width: 50%;
}

/* Clear floats after the columns */
.activityRow:after {
  content: "";
  display: table;
  clear: both;
}

p {
  margin-bottom: 0.1rem;
}