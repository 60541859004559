.file-upload-entry-holder {
	display: flex;
	position: relative;
}

.file-upload-entry-holder .enrollmentProjectFilterBtn {
	margin-right: 10px;
}

.file-upload-entry {
	border: 1px solid #8d948e;
	display: flex;
	margin-bottom: 10px;
	box-shadow: 1px 2px 6px 0 #8d948e, 0px 0px 4px 0 #8d948e;
	position: relative;
}

.file-upload-index {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 25px;
	background-color: #bfd9d9;
}

.file-upload-row {
	display: flex;
}

.file-upload-cell {
	width: 186px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 14px 5px 0px 5px;
	border-left: 1px solid #d7d7d7;
	border-bottom: 1px solid #d7d7d7;
	border-top: 1px solid transparent;
	border-right: 1px solid transparent;
}

.file-upload-cell-error {
	border: 1px solid #c83d3f;
}

.file-upload-label {
	font-size: 10px;
}

.file-entry-overlayComponent {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	margin: 0 !important;
	width: 100% !important;
	opacity: 0.6 !important;
	z-index: 2 !important;
	height: 100%;
	background-color: var(--clr-white);
}

.file-entry-checkbox-holder {
	position: absolute;
	right: -40px;
	top: 70px;
}

.file-entry-checkbox {
	border: 1px solid grey;
	border-radius: 3px;
	background-color: #e5e5e5;
	font-size: 15px;
	height: 22px;
	width: 22px;
	margin-left: 10px;
}

.Select-menu-outer {
	z-index: 999;
}

.google-form-header {
	display: flex;
	justify-content: center;
	height: 100px;
	align-items: center;
	background: rgb(112, 23, 19);
	background: linear-gradient(90deg, rgba(112, 23, 19, 1) 0%, rgba(33, 1, 3, 1) 100%);
	color: #ffe181;
	font-size: 44px;
}

.google-form-body {
	background-color: #f0eeef;
	margin-bottom: 20px;
}

.google-form-field {
	padding: 20px;
}

.google-form-field>span {
	font-weight: 500;
	font-size: 20px;
}

.google-form-field>input[type="text"], .google-form-field>input[type="number"], .google-form-field>input[type="date"], .google-form-field>input[type="email"] {
	background-color: inherit;
	border: none;
	border-bottom: 1px solid black;
	width: 40%;
	outline: none;
}

.google-form-field>input[type="checkbox"] {
	margin: 0px 20px;
}

.google-form-field-label {
	margin-left: 40px;
	font-weight: 500;
}

/* Modal Component */

.modal-background {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2040;
	background-color: #000;
	width: 100vw;
	height: 100vh;
}

.modal-box {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2050;
	width: 100vw;
	height: 100vh;
	justify-content: center;
	align-items: center;
}

.modal-class {
	background-color: #fff;
	max-width: 64vw;
	max-height: 85vh;
	border: none;
	border-radius: 0.3rem;
}

/* Modal Component end */

/* Circular Progress Component start */

.counter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 10px;
}

.counter p {
    margin-top: 16px;
    padding-right: 10px;
}


.counter svg{
    width: 35px;
    height: 35px;
  }

 .counter circle {
    fill: none;
    stroke-width: 3;
  }

 .counter .gray {
      stroke: lightgray;
  }

  /* Circular Progress Component end */

/* Pagination */

.pagination-class div:first-child {
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}

.pagination-class div:last-child.active {
	border-right: none;
}
/* Pagination end */

/* Media Queries */

@media (max-width: 767px) {
	/* Modal Component */
	.modal-class {
		min-width: 90vw;
		max-width: 90vw;
	}
	/* Modal Component end */
}

/* Media Queries */


/* Multiple degree bulk upload */

.bg-red {
	color: red;
}