.studentDashboardContainer {
	width: calc(100% - 60px);
	float: right;
	padding: 10px 3px 10px 0px;
	background-color: #eaedf1;
}

.containerShadow {
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
	-webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
}

.studentDashboardContainer .main-heading {
	background-color: var(--primary-white-2);
	height: 40px;
	padding: 0;
	width: 100%;
	position: relative;
	top: -10px;
	right: -2px;
}

.studentDashboardContainer .main-heading .heading-content {
	z-index: 2;
	position: absolute;
	top: 49%;
	transform: translateY(-50%);
	left: 0;
	padding: 10px;
	right: 0;
	color: #999;
}

.studentDashboardContainer .main-heading .heading-content h1 {
	margin: 0;
	font-size: 18px;
	line-height: 32px;
	font-weight: 100
}

.studentDashboardContainer .main-heading .heading-content h4 {
	margin: 0;
	font-size: 16px;
	line-height: 32px;
	font-weight: 100
}

.companyMentorSummaryTitle.mentor.studentDashboard {
	font-size: 15px;
	font-weight: 500;
}

.companyMentorSummaryTitle.mentor.studentDashboard div{
	margin-right: 10px;
    margin-left: 8px;
    font-size: 18px;
    font-weight: 500;
}
.portfolioStrengthDiv, .platformUpdatesContainer {
	padding: 0;
	margin: 5px;
	height: 270px;
	position: relative;
	float: left;
}

.portfolioStrengthDiv {
	width: calc(75% - 4px);
    margin-left: 10px;
    position: absolute;
    margin: 0;
}

.portfolioStrengthDiv .jobRolesContainer {
	width: 74%;
	height: 230px;
	transition: transform 1s;
}

.portfolioStrengthDiv .jobRolesContainerAdd {
	position: absolute;
	width: 74%;
	transform: translateX(-100%);
	left: 26%;
	top: 44px;
	height: calc(230px - 4px);
	transition: all 1s;
	background-color: white;
	overflow: hidden;
}

.portfolioStrengthDiv .suggestLiner {
	width: 100%;
	height: 40px;
	background-color: white;
	padding: 12px;
	color: #999
}

.portfolioStrengthDiv .jobRolesContainer .box {
	margin: 4px 0px 0 4px;
	width: calc(33.33% - 4px);
	background-color: white;
	position: relative;
	height: calc(50% - 4px);
	padding: 4px;
}

.portfolioStrengthDiv .jobRolesContainer .box .addOverlay {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	padding: 5px;
	background-color: #e1e1e1;
	opacity: 0.94;
}

.portfolioStrengthDiv .jobRolesContainer .box .name {
	font-size: 10px;
	height: 30px;
	color: rgba(0, 0, 0, 0.6);
	margin-bottom: 1px;
	overflow: hidden;
	width: calc(100% - 16px);
}

.portfolioStrengthDiv .jobRolesContainer .box .lastActivity {
	font-size: 9px;
	color: #999;
	margin-bottom: 3px;
	overflow: hidden;
}

.portfolioStrengthDiv .scoreContainer {
	width: 26%;
	background-color: white;
	padding: 5px;
	margin: 4px 0 0 0;
	height: calc(230px - 4px);
	position: relative;
	z-index: 2;
}

.portfolioStrengthDiv .scoreContainer .headerMsg {
	font-size: 0.8vw;
	color: rgb(171, 29, 50);
	margin-top: 10px;
	padding: 0 5px;
}

.portfolioStrengthDiv .scoreContainer .addButtonCont {
	position: absolute;
	bottom: 0;
	height: 30px;
	left: 0;
	width: 100%;
}

.portfolioStrengthDiv .scoreContainer .addButtonCont button {
	border-radius: 50%;
	background-color: #f77103;
	color: white;
	position: relative;
	border: 0;
	width: 35px;
	height: 35px;
	transform: translateY(-50%);
}

.scoreContainer .score {
	font-size: 27px;
	margin-top: 24px;
	margin-bottom: 0;
}

.platformUpdatesContainer {
	width: calc(40% - 20px);
	background-color: white;
}

.platformUpdatesContainer .carousel-indicators {
	margin-bottom: 5px !important;
}

.platformUpdatesContainer .carousel-indicators .active {
	background-color: var(--clr-white) !important;
}

.portfolioStrengthDiv .jobRolesContainerAdd .addBackground {
	width: 33.33%;
	height: 100%;
	position: relative;
}

.portfolioStrengthDiv .jobRolesContainerAdd .addBackground p {
	font-size: 12px;
	text-align: center;
	margin: 23px 0;
}

.portfolioStrengthDiv .jobRolesContainerAdd .icon {
	font-size: 12px;
	position: absolute;
	cursor: pointer;
	right: 2px;
	top: 2px;
	z-index: 5;
}

.portfolioStrengthDiv .jobRolesContainerAdd .addBackground button {
	font-size: 12px;
	background-color: #fff;
	border-radius: 2px;
	border-style: solid;
	margin: 4px 0;
	width: 80%;
}

.portfolioStrengthDiv .jobRolesContainerAdd .addBackground .educationButton {
	border-color: var(--education-color);
	color: var(--education-color);
}

.portfolioStrengthDiv .jobRolesContainerAdd .addBackground .educationButton:hover {
	background-color: var(--education-color);
	color: white;
	border-color: white;
}

.portfolioStrengthDiv .jobRolesContainerAdd .addBackground .exposureButton {
	border-color: var(--exposure-color);
	color: var(--exposure-color);
}

.portfolioStrengthDiv .jobRolesContainerAdd .addBackground .exposureButton:hover {
	background-color: var(--exposure-color);
	border-color: white;
	color: white;
}

.portfolioStrengthDiv .jobRolesContainerAdd .addBackground .experienceButton {
	border-color: var(--experience-color);
	color: var(--experience-color);
}

.portfolioStrengthDiv .jobRolesContainerAdd .addBackground .experienceButton:hover {
	background-color: var(--experience-color);
	border-color: white;
	color: white;
}

.portfolioStrengthDiv .jobRolesContainer .box .strength {
	width: 100%;
	height: 3px;
	position: relative;
	overflow: hidden;
	display: block;
	background-color: #eee;
}

.progressLow, .progressMedium, .progressHigh, .progressExpert {
	height: inherit;
	float: left;
	width: 1%;
	-webkit-animation-duration: 2s;
	/* Safari 4.0 - 8.0 */
	animation-duration: 2s;
	/* animation-delay: 0.1s; */
	-webkit-animation-name: progressLowAnimation;
	/* Safari 4.0 - 8.0 */
	animation-name: progressLowAnimation;
}

.portfolioStrengthDiv .jobRolesContainer .box .strength .progressLow {
	background-color: #006666;
}

.portfolioStrengthDiv .jobRolesContainer .box .strength .progressMedium {
	background-color: #3366ff;
}

.portfolioStrengthDiv .jobRolesContainer .box .strength .progressHigh {
	background-color: #990099;
}

.portfolioStrengthDiv .jobRolesContainer .box .strength .progressExpert {
	background-color: #663399;
}

@-webkit-keyframes progressLowAnimation {
	from {
		width: 0%;
	}
	to {
		width: 100%;
	}
}

@keyframes progressLowAnimation {
	from {
		width: 0%;
	}
	to {
		width: 100%;
	}
}

.portfolioStrengthDiv .jobRolesContainer .box .strengthLegend {
	font-size: 10px;
}

.projectDetailsDiv {
	height: 350px;
	border-color: rgba(255, 255, 255, 0.2);
	padding: 0px;
	margin: 5px;
	/* width: calc(33.33% - 15px); */
	width: calc(40% - 15px);
	position: relative;
	margin-left: 10px;
	float: left;
	/* background-color: var(--primary-white-2); */
	background-color: var(--clr-white);
}

.projectDetailsDiv.pendingTaskDiv {
	height: 350px;
	border-color: rgba(255, 255, 255, 0.2);
	padding: 0px;
	margin: 5px 5px 0;
	/* width: calc(66.66% - 15px); */
	width: calc(60% - 15px);
	position: relative;
	float: left;
	margin-left: 10px;
}

.projectDetailsDiv.careerTrackerCard-Main {
	height: 297px;
	margin: 11px 5px;
	width: calc(28% - 15px);
	position: relative;
	margin-left: 10px;
	background-color: var(--std-function-skin);
	display: flex;
    align-items: center;
	flex-direction: column;
	cursor: pointer;
}

.projectDetailsDiv.careerTrackerCard-Main h2 {
	margin-top: 15px;
    margin-bottom: 0;
    color: var(--mids-grey-2);
    font-size: 3rem;
    font-weight: 500;
}
.projectDetailsDiv.careerTrackerCard-Main .score-title {
	margin-bottom: 10px;
    color: var(--mids-grey-2);
    font-weight: 400;
    position: relative;
    top: -8px;
}
.projectDetailsDiv.careerTrackerCard-Main .tracking-title {
	font-size: 16px;
    font-weight: 500;
    color: var(--clr-white);
    margin-bottom: 20px;
}
.jobRoleTrackerTooltip > .tooltip-inner {
	background-color: var(--mids-grey-2) !important;
	color: var(--clr-white) !important;
	border: var(--mids-grey-2) !important;
}
.jobRoleTrackerTooltip>.arrow::before {
	border-bottom-color: var(--mids-grey-2) !important;
}
.projectDetailsDiv.careerTrackerCard-Main .portfolioIcon {
	height: 80px;
	width: 80px;
}
.projectDetailsDiv.myClubsDiv {
	height: 297px;
	border-color: rgba(255, 255, 255, 0.2);
	padding: 0px;
	margin: 11px 5px;
	width: calc(72% - 15px);
	position: relative;
	margin-left: 10px;
	float: left;
}

.projectDetailsDiv.myClubsDiv .myClubCardDiv{
    display: inline-flex;
    width: 31.8%;
    margin-right: 0.75%;
    height: 200px;
	margin-left: 0.75%;
	position: relative;
	cursor: pointer;
}

.projectDetailsDiv.myClubsDiv .myClubCardDiv .infoSection {
	position: absolute;
    bottom: 27px;
    left: 0;
    width: 100%;
    background-color: var(--spot-yellow);
    padding-bottom: 4px;
}

.projectDetailsDiv.myClubsDiv .myClubCardDiv .infoSection p:first-child {
	color: var(--clr-white);
    margin: 0;
    padding-left: 5px;
    font-size: 15px;
    font-weight: 400;
}
.projectDetailsDiv.myClubsDiv .myClubCardDiv .infoSection p:last-child {
	color: var(--mids-grey-2);
    margin: 0;
	padding-left: 5px;
	font-size: 14px;
    font-weight: 300;

}

.projectDetailsDiv.myClubsDiv .myClubsSectionMainDiv {
	height: inherit;
    padding: 25px 30px;
}

.projectDetailsDiv .header {
	width: 100%;
	display: inline-block;
	position: relative;
	/* border-bottom-width: 1px;
	border-bottom-style: solid;
	border-color: inherit; */
	padding: 9px;
	background-color: var(--spot-yellow);
}

.projectDetailsDiv .header.tabs {
	width: 25%;
	display: inline-block;
	position: relative;
	/* border-bottom-width: 1px;
	border-bottom-style: solid;
	border-color: inherit; */
	padding: 9px;
	margin-right: 7px;
	background-color: var(--spot-yellow);
	box-shadow: 1px 2px 8px var(--mids-grey-1);
}

.projectDetailsDiv .my-learning.intervention-list {
	overflow-y: scroll;
	/* height: 264px; */
	height: 305px;
}

.projectDetailsDiv .my-learning.intervention-list .learning-cards-main {
	background-color: var(--clr-white);
	margin-bottom: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
	padding: 10px 0;
	font-size: 12px;
	border: 1px solid var(--primary-white-2);
	background-color: var(--primary-white-2);
	margin-bottom: 0px;
	border-bottom: 1px solid #c5c5c5;
}
.projectDetailsDiv .my-learning.intervention-list .learning-cards-main.active {
	background-color: var(--clr-white);
}
.projectDetailsDiv .my-learning.intervention-list .learning-cards-main .intervention-img {
	margin-left: 16px;
    width: 40px;
}

@media (min-width:768px) and (max-width:1300px) {
	.projectDetailsDiv .my-learning.intervention-list .learning-cards-main .intervention-detail-section .title{
		width: 30%;
	}
}

.projectDetailsDiv .my-learning.intervention-list .learning-cards-main .intervention-detail-section {
	width: 68%;
    margin-left: 14px;
}
.projectDetailsDiv .my-learning.intervention-list .learning-cards-main .intervention-detail-section p {
	margin: 5px 0;
}
.projectDetailsDiv .my-learning.intervention-list .learning-cards-main .intervention-detail-section .title{
	font-size: 14px;
}
.projectDetailsDiv .my-learning.intervention-list .learning-cards-main .intervention-detail-section .title.active{
	color: var(--spot-yellow);
}
.projectDetailsDiv .my-learning.intervention-list .learning-cards-main .intervention-detail-section .description{
	font-size: 12px;
	font-weight: 100;
}
.projectDetailsDiv .my-learning.intervention-list .learning-cards-main .status-info-section-new {
	display: flex;
	/* flex-direction: column; */
	justify-content: flex-start;
	align-items: flex-end;
	/* width: 10%; */
	width: 16%;
	/* margin-left: 50px; */
	margin-left: 10px;
}
.projectDetailsDiv .my-learning.intervention-list .learning-cards-main .status-info-section {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-end;
	width: 16%;
}
.projectDetailsDiv .my-learning.intervention-list .learning-cards-main .status-info-section p{
	margin: 0;
    position: relative;
    top: 6px;
	right: 0;
	font-size: 11px;
	color: var(--primary-white-3);
}
.projectDetailsDiv .my-learning.intervention-list .learning-cards-main .status-info-section p i {
	font-size: 7px;
    position: relative;
    top: -1px;
}
.learning-cards-main .intervention-detail-section .title .link-to-detail{
	position: relative;
    left: 11px;
    font-size: 12px;
    color: var(--primary-white-3);
}
.projectDetailsDiv .my-learning.intervention-list .learning-cards-main .status-info-section .right-arrow-to-task{
	position: relative;
    right: 2px;
    top: 1px;
	font-size: 13px;	
	color: var(--primary-white-3);
}
.projectDetailsDiv .my-learning.intervention-list .learning-cards-main .status-info-section .right-arrow-to-task.active{
	color: var(--spot-yellow);
}
.learning-cards-main .intervention-detail-section .title {
	font-size: 15px;
	color: var(--mids-grey-2);
}
.learning-cards-main .intervention-detail-section .description {
	font-size: 13px;
	color: var(--description-text-grey);
}

.projectDetailsDiv .header .pendingTaskTab{
	display: inline-block;
	width: 50%;
	padding: 12px;
	background-color: var(--primary-white-2);
}
.projectDetailsDiv .header .pendingTaskTab h4{
	color: var(--mids-grey-3);
}
.projectDetailsDiv .header .pendingTaskTab.active {
	background-color: var(--spot-yellow);
}
.projectDetailsDiv .header .pendingTaskTab.active h4{
	color: var(--clr-white)
}

.actionItemsDiv .header {
	background-color: var(--clr-message);
	width: 90%;
	position: absolute;
	left: 5%;
	top: -15px;
	border-radius: 4px;
	box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .12), 0 6px 20px 0 rgba(0, 0, 0, .12)
}

.actionItemsDiv .header .heading {
	font-size: 15px;
	color: #fff;
	font-weight: 500;
	text-align: center;
}

.portfolioStrengthDiv .scoreContainer .headerMsg {
	font-size: 12px;
	color: rgb(171, 29, 50);
}

.projectDetailsDiv .my-learning.sub-header {
	height: 52px;
	/* background-color: var(--primary-white-2); */
	background-color: var(--clr-white);
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-left: 8px;
    border-top: 1px solid #DFDFE0;
    position: relative;
    top: -5px;
	padding-top: 19px;
	padding-bottom: 8px;
	margin-bottom: 5px;
	padding-left: 16px;
}

.projectDetailsDiv .my-learning.sub-header img {
	/* height: 44px;
	width: 50px; */
	height: 53px;
    width: 60px;
}

.projectDetailsDiv .my-learning.sub-header h4 {
	/* font-size: 16px; */
    /* text-align: right; */
    /* letter-spacing: 0px;
    color: var(--mids-grey-2);
    font-weight: 400;
	margin: 0 0 0 12px; */
	
	font-size: 19px;
    /* text-align: right; */
    letter-spacing: 0px;
    color: var(--mids-grey-2);
    font-weight: 400;
    margin: 0 0 0 12px;
    /* font-weight: 400; */
    font-weight: 300;

}

.projectDetailsDiv .header h4, .portfolioStrengthDiv .suggestLiner .headerCareer {
	font-size: 14px;
    /* text-align: right; */
    letter-spacing: 0px;
    color: #fff;
    font-weight: 500;
    margin: 0;
}

.projectDetailsDiv .project-status-content {
	width: 100%;
	height: 221px;
	float: left;
}

.projectDetailsDiv .projectEnroll {
	background: #fff;
	content: "";
	position: absolute;
	left: 0;
	opacity: .9;
	top: 42px;
	bottom: 0;
	width: 100%;
	height: 226px;
}

.projectDetailsDiv .projectEnroll .content, .eventDetailsDiv .courses-content .content, .jobsInternDetailsDiv .jobs-content .content1, .actionItemsDiv .contentBoxAction .content1 {
	position: absolute;
	display: inline-block;
	width: 100%;
	top: 50%;
	transform: translateY(-50%);
}

.projectDetailsDiv .projectEnroll p, .jobsInternDetailsDiv .jobs-content .content1 .message, .actionItemsDiv .contentBoxAction .content1 .message, .eventDetailsDiv .courses-content .content p {
	font-size: 13px;
	text-align: center;
	color: var(--clr-message);
	padding: 0px 10px;
}

.projectDetailsDiv .project-status-content .status, .dateSection {
	padding: 0 5px;
	margin: 5px 0px;
	position: relative;
}

.projectDetailsDiv .project-status-content .status {
	height: 210px;
}

.projectDetailsDiv .project-status-content .dateSection {
	height: 188px;
	padding: 0;
	margin: 5px;
	width: calc(50% - 10px);
	display: inline-block;
}

.scroll {
	overflow-y: auto;
}

.projectDetailsDiv .project-status-content .status .list {
	margin-bottom: 15px;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	border-color: gray;
}

.projectDetailsDiv .project-status-content .status .content {
	position: absolute;
	display: inline-block;
	width: 100%;
	top: 50%;
	transform: translateY(-50%);
}

.projectDetailsDiv .project-status-content .status p {
	color: gray;
	font-size: 16px;
}

.eventDetailsDiv .project-status-content .dateSection .day {
	font-weight: 100;
	font-size: 12px;
	color: gray;
	text-align: center;
	margin: 0;
	margin-top: 34px;
}

.eventDetailsDiv .project-status-content .dateSection h1 {
	font-size: 70px;
	margin: 0px;
	color: gray;
}

.eventDetailsDiv .project-status-content .dateSection h2 {
	margin: 0px;
	color: gray;
}

.eventDetailsDiv .project-status-content .dateSection .title {
	height: 64px;
	overflow: hidden;
	font-weight: 100;
	font-size: 14px;
	margin-bottom: 10px;
	position: relative;
	text-align: center;
}

.eventDetailsDiv .project-status-content .dateSection .title p {
	position: absolute;
	top: 50%;
	width: 100%;
	max-height: 64px;
	margin: 0;
	transform: translateY(-50%);
}

.eventDetailsDiv .project-status-content .dateSection .title::after {
	background: linear-gradient(0deg, #fff, hsla(0, 0%, 100%, 0) 100%);
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	height: 20px;
	width: 100%;
}

.eventDetailsDiv .project-status-content .dateSection .host, host1 {
	font-size: 12px;
	text-align: center;
}

.eventDetailsDiv .project-status-content .dateSection .host1 {
	margin: 0;
	margin-top: 34px;
	font-size: 12px;
	text-align: center;
}

.eventDetailsDiv .project-status-content .dateSection .actionButtons {
	width: 100%;
	display: inline-block;
}

.eventDetailsDiv .attendButton, .commonFeedback .postButton, .portfolioStrengthDiv .jobRolesContainer .box button {
	background-color: transparent;
	color: var(--experience-color);
	font-size: 12px;
	border: 1px solid;
	border-color: var(--experience-color);
}

.eventDetailsDiv .attendButton:hover, .commonFeedback .postButton:hover, .portfolioStrengthDiv .jobRolesContainer .box button:hover {
	background-color: var(--experience-color);
	color: #fff;
}

.eventDetailsDiv .project-status-content .dateSection .didYouAttend {
	font-size: 13px;
	background-color: rgba(0, 0, 0, 0.2);
	text-align: center;
}

.eventDetailsDivSingle .carousel-item {
	display: block;
}

.linkButton {
	cursor: pointer;
	font-size: 11px;
	color: var(--experience-color);
}

.linkButton:hover, .linkButtonPortfolio:hover {
	color: black;
	text-decoration: underline;
}

.linkButtonPortfolio {
	cursor: pointer;
	font-size: 11px;
	color: var(--experience-color);
	position: absolute;
	right: 12px;
	bottom: 6px;
}

.textWhite {
	color: #fff;
}

.projectDetailsDiv .projectButton, .jobsInternDetailsDiv .jobs-content .content1 .button1, .actionItemsDiv .contentBoxAction .content1 .button1 {
	background-color: transparent;
	color: var(--experience-color);
	border: 1px solid var(--experience-color);
	margin-top: 4px;
	font-size: 14px;
}

.projectButton:hover, .jobsInternDetailsDiv .jobs-content .content1 .button1:hover, .actionItemsDiv .contentBoxAction .content1 .button1:hover {
	background-color: var(--experience-color);
	color: #fff;
}

.projectDetailsDiv .project-status-content .carouselContainer {
	display: block;
	position: relative;
	width: calc(100% - 10px);
	height: 198px;
	left: 5px;
}

.projectDetailsDiv .project-status-content .status .list p {
	/* color: rgba(255, 255, 255, 0.6); */
	font-size: 11px;
	font-weight: 100;
	min-height: 11px;
}

.projectDetailsDiv .project-status-content .status .score {
	font-size: 12px;
	font-weight: 100;
}

.projectDetailsDiv .project-status-content .status .score i {
	color: var(--clr-message);
	cursor: pointer;
}

.projectDetailsDiv .footer, .eventFooter {
	width: calc(100% + 16px);
	position: relative;
	left: -8px;
	display: inline-block;
	text-align: center;
	padding: 9px 0px;
	margin-top: 5px;
}

.eventDetailsDiv .eventFooter {
	background-color: transparent;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.eventDetailsDiv .eventFooter .content {
	font-size: 12px;
	color: gray;
	width: 100%;
	display: block;
	margin: 9px 0px;
}

.eventDetailsDiv .carouselIcon.fa-angle-left {
	color: var(--clr-darkest-grey)
}

.eventDetailsDiv .carouselIcon.fa-angle-right {
	color: var(--clr-darkest-grey)
}

.eventDetailsDiv .carousel-indicators .active {
	background-color: var(--experience-color) !important;
}

.projectDetailsDiv .footer .content {
	display: block;
	cursor: pointer;
}

.projectDetailsDiv .footer .number {
	color: gray;
	font-size: 18px;
	font-weight: bold;
	margin: 0;
}

.projectDetailsDiv .footer .text {
	margin-bottom: 0;
	margin-top: 3px;
	color: gray;
	font-size: 13px;
}

.carouselIcon {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	color: #fff;
	font-weight: 100;
}

.carouselIcon.fa-angle-left {
	left: 5px;
}

.carouselIcon.fa-angle-right {
	right: 5px;
}

.carouselIconEventDetail {
	position: absolute;
	top: 54%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	font-weight: 900px;
	font-size: 32px;
	background-color: grey;
	padding: 7px;
	border-radius: 6px;
	font-weight: 900;
}

.EventEmptyImageDiv {
	height: 250px;
	background-color: #f7f7f7;
	text-align: center;
	position: relative;
}

.EventEmptyImageText {
	position: absolute;
	left: 0;
	top: 36%;
	color: #bfbfbf;
}

.platformUpdatesContainer .cards {
	height: 270px;
	overflow: hidden;
	cursor: pointer;
	padding: 0;
	margin: 0;
	position: relative
}

.platformUpdatesContainer .child {
	height: 100%;
	background-color: var(--clr-black);
	/* fallback color */
	background-position: center;
	background-size: 100% 270px;
	background-repeat: no-repeat;
	transition: all .5s;
}

.platformUpdatesContainer .child::before {
	content: "";
	display: none;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: var(--clr-black);
	opacity: 0.17;
}

.platformUpdatesContainer .cards:hover .child, .platformUpdatesContainer .cards:focus .child {
	transform: scale(1.05);
}

.platformUpdatesContainer .cards:hover .child:before, .platformUpdatesContainer .cards:focus .child:before {
	display: block;
}

.platformUpdatesContainer .contentBox .header {
	margin-top: 3px;
	font-size: 19px;
}

.platformUpdatesContainer .contentBox .description {
	font-size: 1.1vw;
}

.platformUpdatesContainer .contentBox .domain, .platformUpdatesContainer .contentBoxOrg .content .text .domain {
	font-size: 12px;
	color: gray;
	font-weight: 700;
}

.platformUpdatesContainer .contentBox {
	background-color: var(--clr-white);
	opacity: 0.8;
	max-height: calc(100% - 25px);
	position: absolute;
	top: 10px;
	right: 10px;
	padding: 10px;
	overflow: hidden;
}

.platformUpdatesContainer .contentBoxOrg {
	background-color: var(--clr-white);
	opacity: 0.8;
	height: calc(100% - 40px);
	position: absolute;
	top: 10px;
	width: 80%;
	left: 10%;
	padding: 0px 10px;
	overflow: hidden;
}

.platformUpdatesContainer .contentBoxOrg .content .text .header {
	font-size: 15px;
	margin-top: 2px;
	overflow: hidden;
	height: 18px;
	text-decoration: underline;
}

.platformUpdatesContainer .contentBoxOrg .content .text .header:hover {
	color: var(--clr-message)
}

.platformUpdatesContainer .contentBoxOrg .content .text .domain {
	margin-bottom: 0;
}

.platformUpdatesContainer .contentBoxOrg .content .text {
	float: right;
	width: calc(100% - 55px);
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.platformUpdatesContainer .contentBoxOrg .content {
	height: 33.33%;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	width: 100%;
	float: left;
}

.platformUpdatesContainer .contentBoxOrg .content .image {
	float: left;
	width: 45px;
	height: 45px;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	border-radius: 50%;
}

.dummyCarouselContentImg {
	visibility: hidden;
	z-index: -1;
	position: relative;
}

.educationColor {
	color: var(--education-color);
}

.experienceColor {
	color: var(--experience-color);
}

.exposureColor {
	color: var(--exposure-color);
}

.actionItemsDiv {
	background-color: var(--clr-white);
	position: relative;
	margin-top: 21.7px;
	height: 184.3px;
}

.actionItemsDiv .contentBoxAction {
	position: absolute;
	width: 90%;
	left: 5%;
	height: calc(100% - 50px);
	top: 40px;
	overflow-y: scroll;
	-ms-overflow-style: none;
	overflow: -moz-scrollbars-none;
}

.platformUpdatesContainer .cards:hover .child:before, .platformUpdatesContainer .cards:focus .child:before {
	display: block;
}

.platformUpdatesContainer .contentBox .header {
	margin-top: 3px;
	font-size: 19px;
}

.platformUpdatesContainer .contentBox .description {
	font-size: 1.1vw;
}

.platformUpdatesContainer .contentBox .domain, .platformUpdatesContainer .contentBoxOrg .content .text .domain {
	font-size: 12px;
	color: gray;
	font-weight: 700;
}

.platformUpdatesContainer .contentBox {
	background-color: white;
	opacity: 0.8;
	max-height: calc(100% - 25px);
	position: absolute;
	top: 10px;
	right: 10px;
	padding: 10px;
	overflow: hidden;
}

.platformUpdatesContainer .contentBoxOrg {
	background-color: white;
	opacity: 0.8;
	height: calc(100% - 40px);
	position: absolute;
	top: 10px;
	width: 80%;
	left: 10%;
	padding: 0px 10px;
	overflow: hidden;
}

.platformUpdatesContainer .contentBoxOrg .content .text .header {
	font-size: 15px;
	margin-top: 2px;
	overflow: hidden;
	height: 18px;
	text-decoration: underline;
}

.platformUpdatesContainer .contentBoxOrg .content .text .header:hover {
	color: var(--clr-message)
}

.platformUpdatesContainer .contentBoxOrg .content .text .domain {
	margin-bottom: 0;
}

.platformUpdatesContainer .contentBoxOrg .content .text {
	float: right;
	width: calc(100% - 55px);
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.platformUpdatesContainer .contentBoxOrg .content {
	height: 33.33%;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	width: 100%;
	float: left;
}

.platformUpdatesContainer .contentBoxOrg .content .image {
	float: left;
	width: 45px;
	height: 45px;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	border-radius: 50%;
}

.dummyCarouselContentImg {
	visibility: hidden;
	z-index: -1;
	position: relative;
}

.educationColor {
	color: rgb(255, 94, 61);
}

.experienceColor {
	color: rgb(253, 141, 48);
}

.exposureColor {
	color: rgb(242, 177, 15);
}

.actionItemsDiv {
	background-color: #fff;
	position: relative;
	margin-top: 21.7px;
	height: 184.3px;
}

.actionItemsDiv .contentBoxAction {
	position: absolute;
	width: 90%;
	left: 5%;
	height: calc(100% - 50px);
	top: 40px;
	overflow-y: scroll;
	-ms-overflow-style: none;
	overflow: -moz-scrollbars-none;
}

.actionItemsDiv .contentBoxAction .actionList {
	width: 100%;
	padding: 10px 0px;
}

.actionItemsDiv .contentBoxAction .actionList .message {
	font-size: 14px;
	margin: 0;
	color: gray;
	display: inline-block;
	width: calc(100% - 25px);
}

.actionItemsDiv .contentBoxAction .actionList .link {
	display: inline-block;
	width: 20px;
	color: var(--clr-message);
}

.actionItemsDiv .contentBoxAction .actionList .link:hover {
	color: var(--clr-black);
}

.askAQuestionDiv {
	height: 111px;
	background-color: var(--experience-color);
	transition: transform 0.2s;
	cursor: pointer;
}

.askAQuestionDiv:hover {
	transform: scale(1.02);
}

.askAQuestionDiv h2 {
	text-align: center;
	color: var(--clr-white);
	margin: 0
}

.xceleronCoursesDiv .courses-content {
	padding: 0 18px;
}

.xceleronCoursesDiv .courses-loader {
	position: absolute;
	background-color: var(--clr-white);
	opacity: 0.8;
	height: 279px;
	top: 38px;
	left: 0;
}

.xceleronCoursesDiv .courses-content .listItem {
	padding: 7px 0px;
}

.xceleronCoursesDiv .courses-content .listItem .name {
	font-size: 14px;
}

.xceleronCoursesDiv .courses-content .listItem .content {
	font-size: 12px;
}

.xceleronCoursesDiv .courses-content .listItem .date {
	font-size: 9px;
	color: #999;
}

.jobsInternDetailsDiv {
	height: 250px;
}

.jobsInternshipsComponent .carousel-indicators {
	bottom: -14.5%;
}

.jobsInternDetailsDiv .jobs-content {
	height: 232px;
}

.xceleronCoursesDiv .courses-content {
	height: 279px;
}

.jobsInternDetailsDiv .jobs-content, .xceleronCoursesDiv .courses-content {
	width: calc(100% + 16px);
	position: relative;
	left: -8px;
	overflow-y: scroll;
	overflow-x: hidden;
}

.projectDetailsDiv .defaultMessage {
	color: var(--mids-grey-2);
	margin: 0;
	font-size: 14px;
	font-weight: 600;
	padding: 9px;
	/* transform: translateY(-50%);
	text-align: center; */
	/* width: 100%; */
}

.jobsInternDetailsDiv .jobs-content .jobList {
	background-color: var(--clr-snow);
	width: 100%;
	position: relative;
	float: left;
	cursor: pointer;
	/* margin-top: 1%; */
	height: 24%;

	box-shadow: 0px 5px 4px #968e8e;
}

.jobsInternDetailsDiv .jobs-content, .xceleronCoursesDiv .courses-content {
	width: calc(100% + 16px);
	position: relative;
	left: 0;
	overflow-y: scroll;
	overflow-x: hidden;
}
.pendingTaskDiv .pendingTaskHeader {
	/* margin-bottom: 6px; */

	margin-bottom: 6px;
    padding-top: 6px;
    padding-left: 6px;
}
.pendingTaskDiv .pendingTaskHeader .pendingTaskHeader-Main {
	display: flex;
    justify-content: space-between;
}
.pendingTaskHeader-Main .TaskSwitchTabSection {
	display: flex;
	width: 45%;
}
.pendingTaskHeader-Main .TaskSwitchTabSection .taskTab{
	background-color: var(--spot-yellow);
    color: var(--white);
    width: 45%;
    margin-right: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
	border-radius: 3px;
	cursor: pointer;
	padding: 5px;
}
.courses-loader.pendingTask{
	height: 100%; 
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--primary-white-2);
}
.pendingTaskHeader-Main .TaskSwitchTabSection .taskTab.inverted{
	background-color: var(--primary-white-2);
    color: var(--mids-grey-3);
}
.pendingTaskList {
	overflow-y: scroll;
	height: 305px;
	-ms-overflow-style: none;
	overflow: -moz-scrollbars-none;
}
.PendingTask-searchMain {
	position: relative;
	padding-right: 10px;
}
.PendingTask-searchMain .searchIcon {
	position: absolute;
    top: 6px;
    left: 6px;
    color: var(--mids-grey-3);
    font-size: 14px;
    z-index: 1;
}
.PendingTask-searchMain input{
	position: relative;
	/* background-color: var(--clr-snow); */
	background-color: var(--primary-white-2);
	border-radius: 10px;
	/* box-shadow: 11px 9px 30px rgba(55, 84, 170, 0.1), inset 0px 4px 4px rgba(106, 142, 197, 0.1); */
	text-indent: 9px;
    outline: none;
    font-size: 14px;
    border: none;
	padding: 5px 19px;
	color: var(--mids-grey-3);
}
.StudentDashboard-PendingTaskMain {
	height: 100%;
}
.StudentDashboard-PendingTaskMain .task-item-div {
	display: flex;
	margin-bottom: 6px;
	cursor: pointer;
}
.StudentDashboard-PendingTaskMain .task-item-div .section-one{
	/* background-color: #F9F9F9; */
	background-color: var(--primary-white-2);
    width: calc(80% - 15px);
    margin-right: 6px;
	padding: 24px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.StudentDashboard-PendingTaskMain .task-item-div .section-one.full-length {
	width: calc(100%);
}
.StudentDashboard-PendingTaskMain .task-item-div .section-one h4 {
	color: var(--mids-grey-2);
	margin: 0;
	margin-left: 18px;
    font-size: 14px;
}
.StudentDashboard-PendingTaskMain .task-item-div .section-two{
	/* background-color: #F9F9F9; */
	background-color: var(--primary-white-2);
    width: calc(50% - 15px);
	padding: 24px;
	display: flex;
    flex-direction: column;
    justify-content: center;
}
.StudentDashboard-PendingTaskMain .task-item-div .section-two h4{
	color: #5F5F61;
    font-size: 13px;
    font-weight: bold;
}
.StudentDashboard-PendingTaskMain .task-item-div .section-two .statusIcon{
	color: var(--clr-completed-green);
}
.StudentDashboard-PendingTaskMain .task-item-div .section-two .dueIcon {
	width: 35px;
}
.pendingTask {
	background-color: var(--clr-snow);
	margin-top: 6px;
	padding: 5px 0 5px 10px;
}
.StudentDashboard-PendingTaskMain .noPendingTask,
.my-learning.intervention-list .noPendingTask {
	height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
	align-items: center;
	text-align: center;
}
.StudentDashboard-PendingTaskMain .noPendingTask .enrollButton,
.my-learning.intervention-list .noPendingTask .enrollButton,
.projectDetailsDiv.myClubsDiv .noClubsActionItems .noClubSection .enrollButton {
	background-color: var(--spot-yellow);
    color: var(--clr-white);
    outline: none;
    border: none;
    font-size: 14px;
    border-radius: 3px;
    padding: 6px 17px;
    margin-top: 6px;
}
.pendingTask img {
	height: 30px;
	width: 30px;
}

.pendingTask .fa.fa-tasks {
	font-size: 20px;
	padding-left: 5px;
}

.pendingTask .font12 {
	font-weight: 300;
}

.pendingTaskIcon {
	width: 30px;
	height: 30px;
	color: #fff;
	padding: 6px 7px;
	border-radius: 50%;
	font-size: 14px;
	font-weight: 700;
	display: flex;
	justify-content: center;
	align-items: center;
}

.pendingTaskContent {
	font-size: 14px;
	font-weight: 500;
	top: -5px;
}

.pendingTaskContent .description {
	overflow: hidden;
	height: 14px;
	line-height: 14px;
}

.pendingTaskContent .description * {
	font-weight: 300 !important;
	font-size: 13px !important;
	margin-top: 0;
}
.EnrollNewLearning-Main {
	display: flex;
    flex-direction: column;
	align-items: center;
	padding: 25px;
	background-color: var(--clr-white);
}
.EnrollNewLearning-CardMain{
	display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
	width: 93%;
	margin-bottom: 4%;
}
.EnrollNewLearning-Main .Welcome-Main{
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.card-NewEnroll{
	height: 360px;
    border: 1px solid var(--primary-white-2);
    width: 48%;
	margin: 1% 2%;
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.card-NewEnroll-dashboard {
    /* border: 1px solid var(--primary-white-2); */
	width: 100%;
	height: 100%;
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.card-NewEnroll-dashboard p{
	margin-top: 8px;
    text-align: center;
    font-size: 14px;
    padding: 0 25px;
    color: var(--mids-grey-2);
}
.card-NewEnroll-dashboard span{
	font-weight: 500;
	font-size: 20px;
	color: var(--mids-grey-2);
	margin-top: 10px;
}
.card-NewEnroll p{
	margin-top: 8px;
    text-align: center;
    font-size: 14px;
    padding: 0 25px;
    color: var(--mids-grey-2);
}
.card-NewEnroll span{
	font-weight: 500;
	font-size: 20px;
	color: var(--mids-grey-2);
	margin-top: 10px;
}
.projectDetailsDiv.myClubsDiv .noClubsActionItems {
	display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
}
.projectDetailsDiv.myClubsDiv .noClubsActionItems .noClubSection {
	margin-left: 20px;
}
.card-NewEnroll button {
	border: none;
	background-color: transparent;
	color: var(--mids-grey-3);
	margin-bottom: 8px;
}
.card-NewEnroll button i{
	color: var(--spot-yellow);
}
.card-NewEnroll-dashboard button {
	border: none;
	background-color: transparent;
	color: var(--mids-grey-3);
	margin-bottom: 8px;
}
.card-NewEnroll-dashboard button i{
	color: var(--spot-yellow);
}
.EnrollNewLearning-Main .Welcome-Main h4:first-child {
	color: var(--spot-yellow);
	font-size: 22px;
}
.EnrollNewLearning-Main .Welcome-Main h4:last-child {
	color: var(--mids-grey-2);
	font-size: 22px;
}
.studentDashboardContainer *::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 9px;
	height: 9px;
}

.studentDashboardContainer *::-webkit-scrollbar-thumb {
	-webkit-appearance: none;
	box-shadow: inset 0 0 0 2px var(--clr-white);
	background: #A5AFB5;
}

.studentDashboardContainer *::-webkit-scrollbar-track {
	-webkit-appearance: none;
	background: transparent;
}

.CutomTable-TableDiv.StudentDashboardTable {
	margin-bottom: 0;
    margin-top: 6px;
}

.CutomTable-TableDiv.StudentDashboardTable .CustomTable-Table {

}

.CutomTable-TableDiv.StudentDashboardTable .CustomTable-Table .TableHeader {

}

.CutomTable-TableDiv.StudentDashboardTable .CustomTable-Table .TableHeader .headerData {
	background-color: var(--spot-yellow);
    color: var(--clr-white);
    text-align: left;
    padding: 5px 5px 5px 15px;
    font-size: 14px;
}

.CutomTable-TableDiv.StudentDashboardTable .CustomTable-Table .TableBody {
	
}

.CutomTable-TableDiv.StudentDashboardTable .CustomTable-Table .TableBody .TableRow .rowData{
	background-color: #fff;
    border-right: 8px solid var(--primary-white-2);
    padding: 10px;
    font-size: 15px;
    text-align: center;
    border-bottom: 8px solid var(--primary-white-2);
}

@media (min-width:768px) and (max-width:1300px) {
	.portfolioStrengthDiv .jobRolesContainerAdd .addBackground p {
		margin-top: 0;
	}
}

.jobsInternDetailsDiv .jobs-content .jobList {
	background-color: var(--clr-white);
	width: 100%;
	position: relative;
	float: left;
	cursor: pointer;
	margin-top: 1%;
	height: 24%;
}

.jobsInternDetailsDiv .jobs-content .jobList:hover {
	-webkit-box-shadow: 0 8px 8px -6px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0 8px 8px -6px rgba(0, 0, 0, 0.15);
	box-shadow: 0 8px 8px -6px rgba(0, 0, 0, 0.15);
}

.jobsInternDetailsDiv .jobs-content .jobList .jobListContent {
	position: absolute;
	top: 50%;
	width: calc(100% - 16px);
	margin: 0px 8px;
	transform: translateY(-50%);
}

.jobsInternDetailsDiv .jobs-content .jobList .jobListContent .logo {
	height: 45px;
	background-size: 100% 100%;
	background-color: transparent;
	border-radius: 50%;
	width: 45px;
	float: left;
	background-repeat: no-repeat;
}

.jobsInternDetailsDiv .jobs-content .jobList .jobListContent .content {
	width: calc(100% - 110px);
	float: left;
	overflow: hidden;
	height: 45px;
	margin-left: 5px;
}

.jobsInternDetailsDiv .jobs-content .jobList .jobListContent .content .title {
	font-size: 13px;
	margin-bottom: 5px;
}

.jobsInternDetailsDiv .jobs-content .jobList .jobListContent .content .host {
	font-size: 10px;
	margin-bottom: 0
}

.jobsInternDetailsDiv .tabs button {
	font-size: 1vw;
	min-width: 30%;
}

@media (min-width:768px) and (max-width:1300px) {
	.portfolioStrengthDiv .jobRolesContainerAdd .addBackground p {
		margin-top: 0;
	}
}

@media (min-width:768px) {
	.eventDetailsDiv .carousel-indicators {
		bottom: -40px;
	}
}

@media (max-width: 767px) {
	.projectDetailsDiv, .projectDetailsDiv.pendingTaskDiv {
		width: 95%;
		height: fit-content;
		margin: 2.5%;
	}
	.pendingTaskList {
		height: auto;
	}
	.projectDetailsDiv.pendingTaskDiv .pendingTaskList .task-item-div {
		border: 2px solid var(--primary-white-2);
		padding: 12px;
		margin-bottom: 5px;
	}
	.projectDetailsDiv.pendingTaskDiv .pendingTaskList .task-item-div .section-one{
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.projectDetailsDiv.pendingTaskDiv .pendingTaskList .task-item-div .section-one h4{
		font-size: 16px;
		margin-left: 10px;
		margin-top: 10px;
	}
	.projectDetailsDiv.pendingTaskDiv .pendingTaskList .task-item-div .section-two {
		padding: 0 5px;
	}
	.projectDetailsDiv.pendingTaskDiv .pendingTaskList .task-item-div .section-two h4 {
		font-size: 16px;
	}
}